import { RouteConfig } from "vue-router";

const routes: Array<RouteConfig> = [
    {
        path: "/excursions/maintenances/hotels",
        name: "GetAllHotels",
        meta: { title: "Hoteles" },
        component: () =>
            import(/* webpackChunkName: "hotels" */ "../views/Hotels/GetAllHotelsView.vue"),
    }, {
        path: "/excursions/maintenances/hotels/new",
        name: "NewHotel",
        meta: { title: "Hoteles" },
        component: () =>
            import(/* webpackChunkName: "hotels" */ "../views/Hotels/GetHotelView.vue"),
    }, {
        path: "/excursions/maintenances/hotels/:id",
        name: "ViewHotel",
        meta: { title: "Hoteles" },
        component: () =>
            import(/* webpackChunkName: "hotels" */ "../views/Hotels/GetHotelView.vue"),
    }, {
        path: "/excursions/maintenances/hotels/:id/edit",
        name: "EditHotel",
        meta: { title: "Hoteles" },
        component: () =>
            import(/* webpackChunkName: "hotels" */ "../views/Hotels/GetHotelView.vue"),
    },
    {
        path: "/excursions/maintenances/activities",
        name: "GetAllActivities",
        meta: { title: "Actividades" },
        component: () =>
            import(/* webpackChunkName: "activity" */ "../views/Activities/GetAllActivitiesView.vue"),
    }, {
        path: "/excursions/maintenances/activities/new",
        name: "NewActivity",
        meta: { title: "Actividades" },
        component: () =>
            import(/* webpackChunkName: "activity" */ "../views/Activities/GetActivityView.vue"),
    }, {
        path: "/excursions/maintenances/activities/:id",
        name: "ViewActivity",
        meta: { title: "Actividades" },
        component: () =>
            import(/* webpackChunkName: "activity" */ "../views/Activities/GetActivityView.vue"),
    }, {
        path: "/excursions/maintenances/activities/:id/edit",
        name: "EditActivity",
        meta: { title: "Actividades" },
        component: () =>
            import(/* webpackChunkName: "activity" */ "../views/Activities/GetActivityView.vue"),
    },
    {
        path: "/excursions/maintenances/zones",
        name: "GetAllZones",
        meta: { title: "Zonas" },
        component: () =>
            import(/* webpackChunkName: "zones" */ "../views/Zones/GetAllZonesView.vue"),
    }, {
        path: "/excursions/maintenances/zones/new",
        name: "NewZone",
        meta: { title: "Zonas" },
        component: () =>
            import(/* webpackChunkName: "zones" */ "../views/Zones/GetZoneView.vue"),
    }, {
        path: "/excursions/maintenances/zones/:id",
        name: "ViewZone",
        meta: { title: "Zonas" },
        component: () =>
            import(/* webpackChunkName: "zones" */ "../views/Zones/GetZoneView.vue"),
    }, {
        path: "/excursions/maintenances/zones/:id/edit",
        name: "EditZone",
        meta: { title: "Zonas" },
        component: () =>
            import(/* webpackChunkName: "zones" */ "../views/Zones/GetZoneView.vue"),
    }
    , {
        path: "/excursions/maintenances/meeting-points",
        name: "GetAllMeetingPoints",
        meta: { title: "Puntos de encuentro" },
        component: () =>
            import(/* webpackChunkName: "meetingpoints" */ "../views/MeetingPoints/GetAllMeetingPointsView.vue"),
    }, {
        path: "/excursions/maintenances/meeting-points/new",
        name: "NewMeetingPoint",
        meta: { title: "Puntos de encuentro" },
        component: () =>
            import(/* webpackChunkName: "meetingpoints" */ "../views/MeetingPoints/GetMeetingPointView.vue"),
    }, {
        path: "/excursions/maintenances/meeting-points/:id",
        name: "ViewMeetingPoint",
        meta: { title: "Puntos de encuentro" },
        component: () =>
            import(/* webpackChunkName: "meetingpoints" */ "../views/MeetingPoints/GetMeetingPointView.vue"),
    }, {
        path: "/excursions/maintenances/meeting-points/:id/edit",
        name: "EditMeetingPoint",
        meta: { title: "Puntos de encuentro" },
        component: () =>
            import(/* webpackChunkName: "meetingpoints" */ "../views/MeetingPoints/GetMeetingPointView.vue"),
    },
    {
        path: "/excursions/maintenances/agencies",
        name: "GetAllAgencies",
        meta: { title: "Agencias" },
        component: () =>
            import(/* webpackChunkName: "agency" */ "../views/Agencies/GetAllAgenciesView.vue"),
    }, {
        path: "/excursions/maintenances/agencies/new",
        name: "NewAgency",
        meta: { title: "Agencias" },
        component: () =>
            import(/* webpackChunkName: "agency" */ "../views/Agencies/GetAgencyView.vue"),
    }, {
        path: "/excursions/maintenances/agencies/:id",
        name: "ViewAgency",
        meta: { title: "Agencias" },
        component: () =>
            import(/* webpackChunkName: "agency" */ "../views/Agencies/GetAgencyView.vue"),
    }, {
        path: "/excursions/maintenances/agencies/:id/edit",
        name: "EditAgency",
        meta: { title: "Agencias" },
        component: () =>
            import(/* webpackChunkName: "agency" */ "../views/Agencies/GetAgencyView.vue"),
    }
    , {
        path: "/excursions/maintenances/providers",
        name: "GetAllProviders",
        meta: { title: "Proveedores" },
        component: () =>
            import(/* webpackChunkName: "provider" */ "../views/Providers/GetAllProvidersView.vue"),
    }, {
        path: "/excursions/maintenances/providers/new",
        name: "NewProvider",
        meta: { title: "Proveedores" },
        component: () =>
            import(/* webpackChunkName: "provider" */ "../views/Providers/GetProviderView.vue"),
    }, {
        path: "/excursions/maintenances/providers/:id",
        name: "ViewProvider",
        meta: { title: "Proveedores" },
        component: () =>
            import(/* webpackChunkName: "provider" */ "../views/Providers/GetProviderView.vue"),
    }, {
        path: "/excursions/maintenances/providers/:id/edit",
        name: "EditProvider",
        meta: { title: "Proveedores" },
        component: () =>
            import(/* webpackChunkName: "provider" */ "../views/Providers/GetProviderView.vue"),
    }
    , {
        path: "/excursions/maintenances/carriers",
        name: "GetAllCarriers",
        meta: { title: "Transportistas" },
        component: () =>
            import(/* webpackChunkName: "carrier" */ "../views/Carriers/GetAllCarriersView.vue"),
    }, {
        path: "/excursions/maintenances/carriers/new",
        name: "NewCarrier",
        meta: { title: "Transportistas" },
        component: () =>
            import(/* webpackChunkName: "carrier" */ "../views/Carriers/GetCarrierView.vue"),
    }, {
        path: "/excursions/maintenances/carriers/:id",
        name: "ViewCarrier",
        meta: { title: "Transportistas" },
        component: () =>
            import(/* webpackChunkName: "carrier" */ "../views/Carriers/GetCarrierView.vue"),
    }, {
        path: "/excursions/maintenances/carriers/:id/edit",
        name: "EditCarrier",
        meta: { title: "Transportistas" },
        component: () =>
            import(/* webpackChunkName: "carrier" */ "../views/Carriers/GetCarrierView.vue"),
    },
    {
        path: "/excursions/maintenances/products",
        name: "GetAllProducts",
        meta: { title: "Productos" },
        component: () =>
            import(/* webpackChunkName: "product" */ "../views/Products/GetAllProductsView.vue"),
    }, {
        path: "/excursions/maintenances/products/new",
        name: "NewProduct",
        meta: { title: "Productos" },
        component: () =>
            import(/* webpackChunkName: "product" */ "../views/Products/GetProductView.vue"),
    }, {
        path: "/excursions/maintenances/products/:id",
        name: "ViewProduct",
        meta: { title: "Productos" },
        component: () =>
            import(/* webpackChunkName: "product" */ "../views/Products/GetProductView.vue"),
    }, {
        path: "/excursions/maintenances/products/:id/edit",
        name: "EditProduct",
        meta: { title: "Productos" },
        component: () =>
            import(/* webpackChunkName: "product" */ "../views/Products/GetProductView.vue"),
    }
    , {
        path: "/excursions/maintenances/services",
        name: "GetAllServices",
        meta: { title: "Servicios" },
        component: () =>
            import(/* webpackChunkName: "service" */ "../views/Services/GetAllServicesView.vue"),
    }, {
        path: "/excursions/maintenances/services/new",
        name: "NewService",
        meta: { title: "Servicios" },
        component: () =>
            import(/* webpackChunkName: "service" */ "../views/Services/GetServiceView.vue"),
    }, {
        path: "/excursions/maintenances/services/:id",
        name: "ViewService",
        meta: { title: "Servicios" },
        component: () =>
            import(/* webpackChunkName: "service" */ "../views/Services/GetServiceView.vue"),
    }, {
        path: "/excursions/maintenances/services/:id/edit",
        name: "EditService",
        meta: { title: "Servicios" },
        component: () =>
            import(/* webpackChunkName: "service" */ "../views/Services/GetServiceView.vue"),
    }
    , {
        path: "/excursions/maintenances/rates",
        name: "GetAllRates",

        component: () =>
            import(/* webpackChunkName: "rate" */ "../views/Rates/GetAllRatesView.vue"),
    }, {
        path: "/excursions/maintenances/rates/new",
        name: "NewRate",
        component: () =>
            import(/* webpackChunkName: "rate" */ "../views/Rates/GetRateView.vue"),
    }, {
        path: "/excursions/maintenances/rates/:id",
        name: "ViewRate",

        component: () =>
            import(/* webpackChunkName: "rate" */ "../views/Rates/GetRateView.vue"),
    }, {
        path: "/excursions/maintenances/rates/:id/edit",
        name: "EditRate",
        component: () =>
            import(/* webpackChunkName: "rate" */ "../views/Rates/GetRateView.vue"),
    }
    , {
        path: "/excursions/maintenances/guides",
        name: "GetAllGuides",
        meta: { title: "Guías" },
        component: () =>
            import(/* webpackChunkName: "guide" */ "../views/Guides/GetAllGuidesView.vue"),
    }, {
        path: "/excursions/maintenances/guides/new",
        name: "NewGuide",
        meta: { title: "Guías" },
        component: () =>
            import(/* webpackChunkName: "guide" */ "../views/Guides/GetGuideView.vue"),
    }, {
        path: "/excursions/maintenances/guides/:id",
        name: "ViewGuide",
        meta: { title: "Guías" },
        component: () =>
            import(/* webpackChunkName: "guide" */ "../views/Guides/GetGuideView.vue"),
    }, {
        path: "/excursions/maintenances/guides/:id/edit",
        name: "EditGuide",
        meta: { title: "Guías" },
        component: () =>
            import(/* webpackChunkName: "guide" */ "../views/Guides/GetGuideView.vue"),
    }, {
        path: "/excursions/operations/product-scheduling",
        name: "ProductScheduling",
        meta: { title: "Programación y precios" },
        component: () =>
            import(/* webpackChunkName: "operations" */ "../views/Operations/GetProductSchedulingView.vue"),
    }, {
        path: "/excursions/operations/product-restrictions",
        name: "ProductRestrictions",
        meta: { title: "Cierre de ventas" },
        component: () =>
            import(/* webpackChunkName: "operations" */ "../views/Operations/GetProductRestrictionsView.vue"),
    }, {
        path: "/excursions/operations/day-scheduling",
        name: "DayScheduling",
        meta: { title: "Planificación diaria" },
        component: () =>
            import(/* webpackChunkName: "operations" */ "../views/Operations/GetDaySchedulingView.vue"),
    }, {
        path: "/excursions/operations/incidents",
        name: "GetAllIncidents",
        meta: { title: "Incidentes" },
        component: () =>
            import(/* webpackChunkName: "operations" */ "../views/Operations/GetIncidentsView.vue"),
    }, {
        path: "/excursions/operations/day-routes",
        name: "GetDayRoutes",
        meta: { title: "Autobuses en ruta" },
        component: () =>
            import(/* webpackChunkName: "operations" */ "../views/Operations/GetDayRoutesView.vue"),
    },
    {
        path: "/excursions/operations/work-orders",
        name: "GetAllWorkOrders",
        meta: { title: "Órdenes de trabajo" },
        component: () =>
            import(/* webpackChunkName: "workorders" */ "../views/WorkOrders/GetAllWorkOrdersView.vue"),
    },
    {
        path: "/excursions/operations/work-orders/:id",
        name: "ViewWorkOrder",
        meta: { title: "Órdenes de trabajo" },
        component: () =>
            import(/* webpackChunkName: "workorders" */ "../views/WorkOrders/GetWorkOrderView.vue"),
    },
    {
        path: "/excursions/operations/guide-preferences",
        name: "GetGuidePreferences",
        meta: { title: "Planificación de guías" },
        component: () =>
            import(/* webpackChunkName: "guide-preferences" */ "../views/Operations/GetGuidePreferencesView.vue"),
    },
    {
        path: "/excursions/bookings/groups",
        name: "GetAllGroupBookings",
        meta: { title: "Grupos" },
        component: () =>
            import(/* webpackChunkName: "booking" */ "../views/GroupBookings/GetAllGroupBookingsView.vue"),
    }
    , {
        path: "/excursions/bookings/all",
        name: "GetAllBookings",
        meta: { title: "Centro de reservas" },
        component: () =>
            import(/* webpackChunkName: "booking" */ "../views/Bookings/GetAllBookingsView.vue"),
    }
    , {
        path: "/excursions/bookings/new",
        name: "NewBooking",
        meta: { title: "Nueva reserva" },
        component: () =>
            import(/* webpackChunkName: "bookings" */ "../views/Bookings/NewBookingView.vue"),
    }
    , {
        path: "/excursions/bookings/new/:id",
        name: "ConfirmPrebooking",
        meta: { title: "Confirmar prereserva" },
        component: () =>
            import(/* webpackChunkName: "bookings" */ "../views/Bookings/NewBookingView.vue"),
    },
    {
        path: "/excursions/bookings/inbox",
        name: "BookingInbox",
        meta: { title: "Inbox" },
        component: () =>
            import(/* webpackChunkName: "bookings" */ "../views/Bookings/BookingInboxView.vue"),
    }
    , {
        path: "/excursions/bookings/all/:id",
        name: "ViewBooking",
        meta: { title: "Reserva" },
        component: () =>
            import(/* webpackChunkName: "booking" */ "../views/Bookings/GetBookingView.vue"),
    }
    , {
        path: "/excursions/bookings/meeting-points-information",
        name: "MeetingPointsInformation",
        meta: { title: "Puntos de recogida" },
        component: () =>
            import(/* webpackChunkName: "payment" */ "../views/Bookings/MeetingPointsInformationView.vue"),
    }

    , {
        path: "/excursions/maintenances/operators",
        name: "GetAllOperators",
        meta: { title: "Operadores" },
        component: () =>
            import(/* webpackChunkName: "operator" */ "../views/Operators/GetAllOperatorsView.vue"),
    }, {
        path: "/excursions/maintenances/operators/new",
        name: "NewOperator",
        meta: { title: "Operadores" },
        component: () =>
            import(/* webpackChunkName: "operator" */ "../views/Operators/GetOperatorView.vue"),
    }, {
        path: "/excursions/maintenances/operators/:id",
        name: "ViewOperator",
        meta: { title: "Operadores" },
        component: () =>
            import(/* webpackChunkName: "operator" */ "../views/Operators/GetOperatorView.vue"),
    }, {
        path: "/excursions/maintenances/operators/:id/edit",
        name: "EditOperator",
        meta: { title: "Operadores" },
        component: () =>
            import(/* webpackChunkName: "operator" */ "../views/Operators/GetOperatorView.vue"),
    }
    , {
        path: "/excursions/maintenances/routes",
        name: "GetAllRoutes",
        meta: { title: "Rutas" },
        component: () =>
            import(/* webpackChunkName: "route" */ "../views/Routes/GetAllRoutesView.vue"),
    }, {
        path: "/excursions/maintenances/routes/new",
        name: "NewRoute",
        meta: { title: "Rutas" },
        component: () =>
            import(/* webpackChunkName: "route" */ "../views/Routes/GetRouteView.vue"),
    }, {
        path: "/excursions/maintenances/routes/:id",
        name: "ViewRoute",
        meta: { title: "Rutas" },
        component: () =>
            import(/* webpackChunkName: "route" */ "../views/Routes/GetRouteView.vue"),
    }, {
        path: "/excursions/maintenances/routes/:id/edit",
        name: "EditRoute",
        meta: { title: "Rutas" },
        component: () =>
            import(/* webpackChunkName: "route" */ "../views/Routes/GetRouteView.vue"),
    }
    , {
        path: "/excursions/maintenances/activity-categories",
        name: "GetAllActivityCategories",
        meta: { title: "Categorías" },
        component: () =>
            import(/* webpackChunkName: "activitycategory" */ "../views/ActivityCategories/GetAllActivityCategoriesView.vue"),
    }, {
        path: "/excursions/maintenances/activity-categories/new",
        name: "NewActivityCategory",
        meta: { title: "Categorías" },
        component: () =>
            import(/* webpackChunkName: "activitycategory" */ "../views/ActivityCategories/GetActivityCategoryView.vue"),
    }, {
        path: "/excursions/maintenances/activity-categories/:id",
        name: "ViewActivityCategory",

        component: () =>
            import(/* webpackChunkName: "activitycategory" */ "../views/ActivityCategories/GetActivityCategoryView.vue"),
    }, {
        path: "/excursions/maintenances/activity-categories/:id/edit",
        name: "EditActivityCategory",
        meta: { title: "Categorías" },
        component: () =>
            import(/* webpackChunkName: "activitycategory" */ "../views/ActivityCategories/GetActivityCategoryView.vue"),
    },
    {
        path: "/excursions/maintenances/series",
        name: "GetAllSeries",
        meta: { title: "Series de facturación" },
        component: () =>
            import(/* webpackChunkName: "series" */ "../views/Series/GetAllSeriesView.vue"),
    }, {
        path: "/excursions/maintenances/series/new",
        name: "NewSeries",
        meta: { title: "Series de facturación" },
        component: () =>
            import(/* webpackChunkName: "series" */ "../views/Series/GetSeriesView.vue"),
    }, {
        path: "/excursions/maintenances/series/:id",
        name: "ViewSeries",
        meta: { title: "Series de facturación" },
        component: () =>
            import(/* webpackChunkName: "series" */ "../views/Series/GetSeriesView.vue"),
    }, {
        path: "/excursions/maintenances/series/:id/edit",
        name: "EditSeries",
        meta: { title: "Series de facturación" },
        component: () =>
            import(/* webpackChunkName: "series" */ "../views/Series/GetSeriesView.vue"),
    }
    , {
        path: "/excursions/invoicing/sales-invoices",
        name: "GetAllSalesInvoices",
        meta: { title: "Facturas de venta" },
        component: () =>
            import(/* webpackChunkName: "salesinvoice" */ "../views/SalesInvoices/GetAllSalesInvoicesView.vue"),
    }, {
        path: "/excursions/invoicing/sales-invoices/new",
        name: "NewSalesInvoice",
        meta: { title: "Facturas de venta" },
        component: () =>
            import(/* webpackChunkName: "salesinvoice" */ "../views/SalesInvoices/GetSalesInvoiceView.vue"),
    }, {
        path: "/excursions/invoicing/sales-invoices/:id",
        name: "ViewSalesInvoice",
        meta: { title: "Facturas de venta" },
        component: () =>
            import(/* webpackChunkName: "salesinvoice" */ "../views/SalesInvoices/GetSalesInvoiceView.vue"),
    }, {
        path: "/excursions/invoicing/sales-invoices/:id/edit",
        name: "EditSalesInvoice",
        meta: { title: "Facturas de venta" },
        component: () =>
            import(/* webpackChunkName: "salesinvoice" */ "../views/SalesInvoices/GetSalesInvoiceView.vue"),
    },
    {
        path: "/excursions/settlements/agencies",
        name: "AgencySettlement",
        meta: { title: "Liquidaciones de agencias" },
        component: () =>
            import(/* webpackChunkName: "settlement" */ "../views/Billing/AgencySettlementView.vue"),
    },
    {
        path: "/excursions/settlements/budgets",
        name: "BudgetSettlement",
        meta: { title: "Liquidaciones de grupos" },
        component: () =>
            import(/* webpackChunkName: "settlement" */ "../views/Billing/BudgetSettlementView.vue"),
    },
    {
        path: "/excursions/maintenances/taxes",
        name: "GetAllTaxes",
        meta: { title: "Impuestos" },
        component: () =>
            import(/* webpackChunkName: "tax" */ "../views/Taxes/GetAllTaxesView.vue"),
    }, {
        path: "/excursions/maintenances/taxes/new",
        name: "NewTax",
        meta: { title: "Impuestos" },
        component: () =>
            import(/* webpackChunkName: "tax" */ "../views/Taxes/GetTaxView.vue"),
    }, {
        path: "/excursions/maintenances/taxes/:id",
        name: "ViewTax",
        meta: { title: "Impuestos" },
        component: () =>
            import(/* webpackChunkName: "tax" */ "../views/Taxes/GetTaxView.vue"),
    }, {
        path: "/excursions/maintenances/taxes/:id/edit",
        name: "EditTax",
        meta: { title: "Impuestos" },
        component: () =>
            import(/* webpackChunkName: "tax" */ "../views/Taxes/GetTaxView.vue"),
    }, {
        path: "/excursions/maintenances/settlement-rules",
        name: "GetAllSettlementRules",
        meta: { title: "Reglas de liquidación" },
        component: () =>
            import(/* webpackChunkName: "settlementrule" */ "../views/SettlementRules/GetAllSettlementRulesView.vue"),
    }, {
        path: "/excursions/maintenances/settlement-rules/new",
        name: "NewSettlementRule",
        meta: { title: "Reglas de liquidación" },
        component: () =>
            import(/* webpackChunkName: "settlementrule" */ "../views/SettlementRules/GetSettlementRuleView.vue"),
    }, {
        path: "/excursions/maintenances/settlement-rules/:id",
        name: "ViewSettlementRule",
        meta: { title: "Reglas de liquidación" },
        component: () =>
            import(/* webpackChunkName: "settlementrule" */ "../views/SettlementRules/GetSettlementRuleView.vue"),
    }, {
        path: "/excursions/maintenances/settlement-rules/:id/edit",
        name: "EditSettlementRule",
        meta: { title: "Reglas de liquidación" },
        component: () =>
            import(/* webpackChunkName: "settlementrule" */ "../views/SettlementRules/GetSettlementRuleView.vue"),
    }
    , {
        path: "/excursions/settlements/operators",
        name: "OperatorSettlement",
        meta: { title: "Liquidación de operadores" },
        component: () =>
            import(/* webpackChunkName: "settlement" */ "../views/Billing/OperatorSettlementView.vue"),
    }
    , {
        path: "/excursions/settlements/carriers",
        name: "CarrierSettlement",
        meta: { title: "Liquidación de transportistas" },
        component: () =>
            import(/* webpackChunkName: "settlement" */ "../views/Billing/CarrierSettlementView.vue"),
    }
    , {
        path: "/excursions/settlements/providers",
        name: "ProviderSettlement",
        meta: { title: "Liquidación de proveedores" },
        component: () =>
            import(/* webpackChunkName: "settlement" */ "../views/Billing/ProviderSettlementView.vue"),
    }
    , {
        path: "/excursions/settlements/guides",
        name: "GuideSettlement",
        meta: { title: "Liquidación de guías" },
        component: () =>
            import(/* webpackChunkName: "settlement" */ "../views/Billing/GuideSettlementView.vue"),
    }
    , {
        path: "/excursions/invoicing/purchase-invoices",
        name: "GetAllPurchaseInvoices",
        meta: { title: "Facturas de compra" },
        component: () =>
            import(/* webpackChunkName: "purchaseinvoice" */ "../views/PurchaseInvoices/GetAllPurchaseInvoicesView.vue"),
    }, {
        path: "/excursions/invoicing/purchase-invoices/new",
        name: "NewPurchaseInvoice",
        meta: { title: "Facturas de compra" },
        component: () =>
            import(/* webpackChunkName: "purchaseinvoice" */ "../views/PurchaseInvoices/GetPurchaseInvoiceView.vue"),
    }, {
        path: "/excursions/invoicing/purchase-invoices/:id",
        name: "ViewPurchaseInvoice",
        meta: { title: "Facturas de compra" },
        component: () =>
            import(/* webpackChunkName: "purchaseinvoice" */ "../views/PurchaseInvoices/GetPurchaseInvoiceView.vue"),
    }, {
        path: "/excursions/invoicing/purchase-invoices/:id/edit",
        name: "EditPurchaseInvoice",
        meta: { title: "Facturas de compra" },
        component: () =>
            import(/* webpackChunkName: "purchaseinvoice" */ "../views/PurchaseInvoices/GetPurchaseInvoiceView.vue"),
    }
    , {
        path: "/excursions/maintenances/guide-rates",
        name: "GetAllGuideRates",
        meta: { title: "Tarifas de guía" },
        component: () =>
            import(/* webpackChunkName: "guiderate" */ "../views/GuideRates/GetAllGuideRatesView.vue"),
    }, {
        path: "/excursions/maintenances/guide-rates/new",
        name: "NewGuideRate",
        meta: { title: "Tarifas de guía" },
        component: () =>
            import(/* webpackChunkName: "guiderate" */ "../views/GuideRates/GetGuideRateView.vue"),
    }, {
        path: "/excursions/maintenances/guide-rates/:id",
        name: "ViewGuideRate",
        meta: { title: "Tarifas de guía" },
        component: () =>
            import(/* webpackChunkName: "guiderate" */ "../views/GuideRates/GetGuideRateView.vue"),
    }, {
        path: "/excursions/maintenances/guide-rates/:id/edit",
        name: "EditGuideRate",
        meta: { title: "Tarifas de guía" },
        component: () =>
            import(/* webpackChunkName: "guiderate" */ "../views/GuideRates/GetGuideRateView.vue"),
    }
    , {
        path: "/excursions/bookings/budgets",
        name: "GetAllBudgets",
        meta: { title: "Presupuestos" },
        component: () =>
            import(/* webpackChunkName: "budget" */ "../views/Budgets/GetAllBudgetsView.vue"),
    }, {
        path: "/excursions/bookings/budgets/new",
        name: "NewBudget",
        meta: { title: "Presupuestos" },
        component: () =>
            import(/* webpackChunkName: "budget" */ "../views/Budgets/NewBudgetView.vue"),
    }, {
        path: "/excursions/bookings/budgets/:id",
        name: "ViewBudget",
        meta: { title: "Presupuestos" },
        component: () =>
            import(/* webpackChunkName: "budget" */ "../views/Budgets/GetBudgetView.vue"),
    }, {
        path: "/excursions/bookings/budgets/:id/edit",
        name: "EditBudget",
        meta: { title: "Presupuestos" },
        component: () =>
            import(/* webpackChunkName: "budget" */ "../views/Budgets/GetBudgetView.vue"),
    },
    // ActivitySchedules
    {
        path: "/excursions/maintenances/activityschedules",
        name: "GetAllActivitySchedules",
        meta: { title: "Programas de actividades" },
        component: () => import(/* webpackChunkName: "activityschedule" */ "../views/ActivitySchedules/GetAllActivitySchedulesView.vue"),
    },
    {
        path: "/excursions/maintenances/activityschedules/new",
        name: "NewActivitySchedule",
        meta: { title: "Programas de actividades" },
        component: () => import(/* webpackChunkName: "activityschedule" */ "../views/ActivitySchedules/GetActivityScheduleView.vue"),
    },
    {
        path: "/excursions/maintenances/activityschedules/:id",
        name: "ViewActivitySchedule",
        meta: { title: "Programas de actividades" },
        component: () => import(/* webpackChunkName: "activityschedule" */ "../views/ActivitySchedules/GetActivityScheduleView.vue"),
    },
    {
        path: "/excursions/maintenances/activityschedules/:id/edit",
        name: "EditActivitySchedule",
        meta: { title: "Programas de actividades" },
        component: () => import(/* webpackChunkName: "activityschedule" */ "../views/ActivitySchedules/GetActivityScheduleView.vue"),
    },
    // Leads
    {
        path: "/excursions/maintenances/leads",
        name: "GetAllLeads",
        meta: { title: "Leads" },
        component: () => import(/* webpackChunkName: "lead" */ "../views/Leads/GetAllLeadsView.vue"),
    },
    {
        path: "/excursions/maintenances/leads/new",
        name: "NewLead",
        meta: { title: "Leads" },
        component: () => import(/* webpackChunkName: "lead" */ "../views/Leads/GetLeadView.vue"),
    },
    {
        path: "/excursions/maintenances/leads/:id",
        name: "ViewLead",
        meta: { title: "Leads" },
        component: () => import(/* webpackChunkName: "lead" */ "../views/Leads/GetLeadView.vue"),
    },
    {
        path: "/excursions/maintenances/leads/:id/edit",
        name: "EditLead",
        meta: { title: "Leads" },
        component: () => import(/* webpackChunkName: "lead" */ "../views/Leads/GetLeadView.vue"),
    },
];


export default routes;
