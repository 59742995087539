import { RouteConfig } from "vue-router";

const routes: Array<RouteConfig> = [
    {
        path: "/global/configuration/maintenance",
        name: "Maintenance",
        meta: { title: "Mantenimiento" },
        component: () =>
            import(/* webpackChunkName: "maintenance" */ "../views/Maintenance/MaintenanceView.vue"),
    },
    {
        path: "/global/help-center",
        name: "HelpCenter",
        meta: { title: "Centro de ayuda" },
        component: () =>
            import(/* webpackChunkName: "helpcenter" */ "../views/HelpCenter/HelpCenterView.vue"),
    },
    {
        path: "/global/help-center/:id",
        name: "HelpCenterPage",
        meta: { title: "Centro de ayuda" },
        component: () =>
            import(/* webpackChunkName: "helpcenter" */ "../views/HelpCenter/HelpCenterView.vue"),
    },
    {
        path: "/global/report-center",
        name: "ReportCenter",
        meta: { title: "Centro de informes" },
        component: () =>
            import(/* webpackChunkName: "report-center" */ "../views/ReportCenter/ReportCenterView.vue"),
    },
    {
        path: "/global/report-center/:id",
        name: "ReportCenterCategory",
        meta: { title: "Centro de informes" },
        component: () =>
            import(/* webpackChunkName: "report-center" */ "../views/ReportCenter/ReportCenterView.vue"),
    },
    {
        path: "/global/report-center/:categoryId/new",
        name: "NewReport",
        meta: { title: "Centro de informes" },
        component: () => import(/* webpackChunkName: "report-center" */ "../views/ReportCenter/GetReportView.vue"),
    }, {
        path: "/global/report-center/:categoryId/:id",
        name: "ViewReport",
        meta: { title: "Centro de informes" },
        component: () => import(/* webpackChunkName: "report-center" */ "../views/ReportCenter/GetReportView.vue"),
    }, {
        path: "/global/report-center/:categoryId/:id/edit",
        name: "EditReport",
        meta: { title: "Centro de informes" },
        component: () => import(/* webpackChunkName: "report-center" */ "../views/ReportCenter/GetReportView.vue"),
    },
    {
        path: "/global/reports/documentation",
        name: "documentation-report",
        meta: { title: "Documentación" },
        component: () =>
            import(/* webpackChunkName: "reports" */ "../views/Reports/DocumentationReportView.vue"),
    }, {
        path: "/global/administration/users/:type",
        name: "GetAllUsers",
        meta: { title: "Usuarios" },
        component: () =>
            import(/* webpackChunkName: "users" */ "../views/Users/GetAllUsersView.vue"),
    }, {
        path: "/global/administration/users/:type/new",
        name: "NewUser",
        meta: { title: "Usuarios" },
        component: () =>
            import(/* webpackChunkName: "users" */ "../views/Users/GetUserView.vue"),
    }, {
        path: "/global/administration/users/:type/:id",
        name: "ViewUser",
        meta: { title: "Usuarios" },
        component: () =>
            import(/* webpackChunkName: "users" */ "../views/Users/GetUserView.vue"),
    }, {
        path: "/global/administration/users/:type/:id/edit",
        name: "EditUser",
        meta: { title: "Usuarios" },
        component: () =>
            import(/* webpackChunkName: "users" */ "../views/Users/GetUserView.vue"),
    },
    {
        path: "/global/administration/profiles",
        name: "GetAllProfiles",
        meta: { title: "Perfiles" },
        component: () =>
            import(/* webpackChunkName: "profile" */ "../views/Profiles/GetAllProfilesView.vue"),
    }, {
        path: "/global/administration/profiles/new",
        name: "NewProfile",
        meta: { title: "Perfiles" },
        component: () =>
            import(/* webpackChunkName: "profile" */ "../views/Profiles/GetProfileView.vue"),
    }, {
        path: "/global/administration/profiles/:id",
        name: "ViewProfile",
        meta: { title: "Perfiles" },
        component: () =>
            import(/* webpackChunkName: "profile" */ "../views/Profiles/GetProfileView.vue"),
    }, {
        path: "/global/administration/profiles/:id/edit",
        name: "EditProfile",
        meta: { title: "Perfiles" },
        component: () =>
            import(/* webpackChunkName: "profile" */ "../views/Profiles/GetProfileView.vue"),
    }
    , {
        path: "/global/content/elements",
        name: "GetAllContentElements",
        meta: { title: "Contenido" },
        component: () =>
            import(/* webpackChunkName: "contentelement" */ "../views/ContentElements/GetAllContentElementsView.vue"),
    }, {
        path: "/global/content/elements/new",
        name: "NewContentElement",
        meta: { title: "Contenido" },
        component: () =>
            import(/* webpackChunkName: "contentelement" */ "../views/ContentElements/GetContentElementView.vue"),
    }, {
        path: "/global/content/elements/:id",
        name: "ViewContentElement",
        meta: { title: "Contenido" },
        component: () =>
            import(/* webpackChunkName: "contentelement" */ "../views/ContentElements/GetContentElementView.vue"),
    }, {
        path: "/global/content/elements/:id/edit",
        name: "EditContentElement",
        meta: { title: "Contenido" },
        component: () =>
            import(/* webpackChunkName: "contentelement" */ "../views/ContentElements/GetContentElementView.vue"),
    }
    , {
        path: "/global/content/images",
        name: "GetAllImages",
        meta: { title: "Imágenes" },
        component: () =>
            import(/* webpackChunkName: "contentelement" */ "../views/Images/GetAllImagesView.vue"),
    }, {
        path: "/global/content/images/:id",
        name: "ViewImage",
        meta: { title: "Imágenes" },
        component: () =>
            import(/* webpackChunkName: "Image" */ "../views/Images/GetImageView.vue"),
    }, {
        path: "/global/content/images/:id/edit",
        name: "EditImage",
        meta: { title: "Imágenes" },
        component: () =>
            import(/* webpackChunkName: "Image" */ "../views/Images/GetImageView.vue"),
    },
    {
        path: "/global/configuration/websites",
        name: "GetAllWebsites",
        meta: { title: "Sitios web" },
        component: () =>
            import(/* webpackChunkName: "website" */ "../views/Websites/GetAllWebsitesView.vue"),
    }, {
        path: "/global/configuration/websites/new",
        name: "NewWebsite",
        meta: { title: "Sitios web" },
        component: () =>
            import(/* webpackChunkName: "website" */ "../views/Websites/GetWebsiteView.vue"),
    }, {
        path: "/global/configuration/websites/:id",
        name: "ViewWebsite",
        meta: { title: "Sitios web" },
        component: () =>
            import(/* webpackChunkName: "website" */ "../views/Websites/GetWebsiteView.vue"),
    }, {
        path: "/global/configuration/websites/:id/edit",
        name: "EditWebsite",
        meta: { title: "Sitios web" },
        component: () =>
            import(/* webpackChunkName: "website" */ "../views/Websites/GetWebsiteView.vue"),
    }
    , {
        path: "/global/administration/e-payments",
        name: "GetAllPayments",
        meta: { title: "Pagos electrónicos" },
        component: () =>
            import(/* webpackChunkName: "payment" */ "../views/Payments/GetAllPaymentsView.vue"),
    }, {
        path: "/global/administration/e-payments/new",
        name: "NewPayment",
        meta: { title: "Pagos electrónicos" },
        component: () =>
            import(/* webpackChunkName: "payment" */ "../views/Payments/GetPaymentView.vue"),
    }, {
        path: "/global/administration/e-payments/:id",
        name: "ViewPayment",
        meta: { title: "Pagos electrónicos" },
        component: () =>
            import(/* webpackChunkName: "payment" */ "../views/Payments/GetPaymentView.vue"),
    }, {
        path: "/global/administration/e-payments/:id/edit",
        name: "EditPayment",
        meta: { title: "Pagos electrónicos" },
        component: () =>
            import(/* webpackChunkName: "payment" */ "../views/Payments/GetPaymentView.vue"),
    },
    {
        path: "/global/configuration/mail-accounts",
        name: "GetAllMailAccounts",
        meta: { title: "Cuentas de correo" },
        component: () =>
            import(/* webpackChunkName: "mailaccount" */ "../views/MailAccounts/GetAllMailAccountsView.vue"),
    }
    , {
        // WARNING: Update to this route requires changes in Google OAuth2 configuration
        path: "/global/configuration/oauth",
        name: "EndMailAccountAuthorization",
        meta: { title: "Autorización" },
        component: () =>
            import(/* webpackChunkName: "mailaccount" */ "../views/MailAccounts/EndMailAccountAuthorizationView.vue"),
    }
    , {
        path: "/global/configuration/integrations",
        name: "GetAllIntegrations",
        meta: { title: "Integraciones" },
        component: () =>
            import(/* webpackChunkName: "integration" */ "../views/Integrations/GetAllIntegrationsView.vue"),
    }, {
        path: "/global/configuration/integrations/:id",
        name: "ViewIntegration",
        meta: { title: "Integraciones" },
        component: () =>
            import(/* webpackChunkName: "integration" */ "../views/Integrations/GetIntegrationView.vue"),
    }
    , {
        path: "/global/content/message-templates",
        name: "GetAllMessageTemplates",
        meta: { title: "Plantillas" },
        component: () =>
            import(/* webpackChunkName: "messagetemplate" */ "../views/MessageTemplates/GetAllMessageTemplatesView.vue"),
    }, {
        path: "/global/content/message-templates/new",
        name: "NewMessageTemplate",
        meta: { title: "Plantillas" },
        component: () =>
            import(/* webpackChunkName: "messagetemplate" */ "../views/MessageTemplates/GetMessageTemplateView.vue"),
    }, {
        path: "/global/content/message-templates/:id",
        name: "ViewMessageTemplate",
        meta: { title: "Plantillas" },
        component: () =>
            import(/* webpackChunkName: "messagetemplate" */ "../views/MessageTemplates/GetMessageTemplateView.vue"),
    }, {
        path: "/global/content/message-templates/:id/edit",
        name: "EditMessageTemplate",
        meta: { title: "Plantillas" },
        component: () =>
            import(/* webpackChunkName: "messagetemplate" */ "../views/MessageTemplates/GetMessageTemplateView.vue"),
    }
    , {
        path: "/global/configuration/notifications",
        name: "GetAllNotifications",
        meta: { title: "Notificaciones" },
        component: () =>
            import(/* webpackChunkName: "notification" */ "../views/Notifications/GetAllNotificationsView.vue"),
    },
    {
        path: "/global/administration/workflows",
        name: "GetAllWorkFlows",
        meta: { title: "Flujos de trabajo" },
        component: () =>
            import(/* webpackChunkName: "workflow" */ "../views/WorkFlows/GetAllWorkFlowsView.vue"),
    }, {
        path: "/global/administration/workflows/new",
        name: "NewWorkFlow",
        meta: { title: "Flujos de trabajo" },
        component: () =>
            import(/* webpackChunkName: "workflow" */ "../views/WorkFlows/GetWorkFlowView.vue"),
    }, {
        path: "/global/administration/workflows/:id",
        name: "ViewWorkFlow",
        meta: { title: "Flujos de trabajo" },
        component: () =>
            import(/* webpackChunkName: "workflow" */ "../views/WorkFlows/GetWorkFlowView.vue"),
    }, {
        path: "/global/administration/workflows/:id/edit",
        name: "EditWorkFlow",
        meta: { title: "Flujos de trabajo" },
        component: () =>
            import(/* webpackChunkName: "workflow" */ "../views/WorkFlows/GetWorkFlowView.vue"),
    }
    , {
        path: "/global/websites/:websiteId/pages",
        name: "GetAllPages",
        meta: { title: "Páginas" },
        component: () =>
            import(/* webpackChunkName: "page" */ "../views/Pages/GetAllPagesView.vue"),
    }, {
        path: "/global/websites/:websiteId/pages/new",
        name: "NewPage",
        meta: { title: "Páginas" },
        component: () =>
            import(/* webpackChunkName: "page" */ "../views/Pages/GetPageView.vue"),
    }, {
        path: "/global/websites/:websiteId/pages/:id",
        name: "ViewPage",
        meta: { title: "Páginas" },
        component: () =>
            import(/* webpackChunkName: "page" */ "../views/Pages/GetPageView.vue"),
    }, {
        path: "/global/websites/:websiteId/pages/:id/edit",
        name: "EditPage",
        meta: { title: "Páginas" },
        component: () =>
            import(/* webpackChunkName: "page" */ "../views/Pages/GetPageView.vue"),
    }
    , {
        path: "/global/configuration/payment-methods",
        name: "GetAllPaymentMethods",
        meta: { title: "Métodos de pago" },
        component: () =>
            import(/* webpackChunkName: "paymentmethod" */ "../views/PaymentMethods/GetAllPaymentMethodsView.vue"),
    }, {
        path: "/global/configuration/payment-methods/new",
        name: "NewPaymentMethod",
        meta: { title: "Métodos de pago" },
        component: () =>
            import(/* webpackChunkName: "paymentmethod" */ "../views/PaymentMethods/GetPaymentMethodView.vue"),
    }, {
        path: "/global/configuration/payment-methods/:id",
        name: "ViewPaymentMethod",
        meta: { title: "Métodos de pago" },
        component: () =>
            import(/* webpackChunkName: "paymentmethod" */ "../views/PaymentMethods/GetPaymentMethodView.vue"),
    }, {
        path: "/global/configuration/payment-methods/:id/edit",
        name: "EditPaymentMethod",
        meta: { title: "Métodos de pago" },
        component: () =>
            import(/* webpackChunkName: "paymentmethod" */ "../views/PaymentMethods/GetPaymentMethodView.vue"),
    }
    , {
        path: "/global/administration/automatic-notification-rules",
        name: "GetAllAutomaticNotificationRules",
        meta: { title: "Reglas de notificación" },
        component: () =>
            import(/* webpackChunkName: "automaticnotificationrule" */ "../views/AutomaticNotificationRules/GetAllAutomaticNotificationRulesView.vue"),
    }, {
        path: "/global/administration/automatic-notification-rules/new",
        name: "NewAutomaticNotificationRule",
        meta: { title: "Reglas de notificación" },
        component: () =>
            import(/* webpackChunkName: "automaticnotificationrule" */ "../views/AutomaticNotificationRules/GetAutomaticNotificationRuleView.vue"),
    }, {
        path: "/global/administration/automatic-notification-rules/:id",
        name: "ViewAutomaticNotificationRule",
        meta: { title: "Reglas de notificación" },
        component: () =>
            import(/* webpackChunkName: "automaticnotificationrule" */ "../views/AutomaticNotificationRules/GetAutomaticNotificationRuleView.vue"),
    }, {
        path: "/global/administration/automatic-notification-rules/:id/edit",
        name: "EditAutomaticNotificationRule",
        meta: { title: "Reglas de notificación" },
        component: () =>
            import(/* webpackChunkName: "automaticnotificationrule" */ "../views/AutomaticNotificationRules/GetAutomaticNotificationRuleView.vue"),
    }
    , {
        path: "/global/administration/seasons",
        name: "GetAllSeasons",
        meta: { title: "Temporadas" },
        component: () =>
            import(/* webpackChunkName: "season" */ "../views/Seasons/GetAllSeasonsView.vue"),
    }, {
        path: "/global/administration/seasons/new",
        name: "NewSeason",
        meta: { title: "Temporadas" },
        component: () =>
            import(/* webpackChunkName: "season" */ "../views/Seasons/GetSeasonView.vue"),
    }, {
        path: "/global/administration/seasons/:id",
        name: "ViewSeason",
        meta: { title: "Temporadas" },
        component: () =>
            import(/* webpackChunkName: "season" */ "../views/Seasons/GetSeasonView.vue"),
    }, {
        path: "/global/administration/seasons/:id/edit",
        name: "EditSeason",
        meta: { title: "Temporadas" },
        component: () =>
            import(/* webpackChunkName: "season" */ "../views/Seasons/GetSeasonView.vue"),
    }
    , {
        path: "/global/configuration/api-keys",
        name: "GetAllKeys",
        meta: { title: "Claves de acceso al API" },
        component: () =>
            import(/* webpackChunkName: "key" */ "../views/Keys/GetAllKeysView.vue"),
    }, {
        path: "/global/configuration/api-keys/new",
        name: "NewKey",
        meta: { title: "Claves de acceso al API" },
        component: () =>
            import(/* webpackChunkName: "key" */ "../views/Keys/GetKeyView.vue"),
    }, {
        path: "/global/configuration/api-keys/:id",
        name: "ViewKey",
        meta: { title: "Claves de acceso al API" },
        component: () =>
            import(/* webpackChunkName: "key" */ "../views/Keys/GetKeyView.vue"),
    }, {
        path: "/global/configuration/api-keys/:id/edit",
        name: "EditKey",
        meta: { title: "Claves de acceso al API" },
        component: () =>
            import(/* webpackChunkName: "key" */ "../views/Keys/GetKeyView.vue"),
    }
    ,
    {
        path: "/global/configuration/settings",
        name: "EditConfiguration",
        meta: { title: "Parámetros del sistema" },
        component: () =>
            import(/* webpackChunkName: "configuration" */ "../views/Configurations/GetConfigurationView.vue"),
    },
    // Organizations
    {
        path: "/global/administration/organizations",
        name: "GetAllOrganizations",
        meta: { title: "Empresas" },
        component: () => import(/* webpackChunkName: "organization" */ "../views/Organizations/GetAllOrganizationsView.vue"),
    },
    {
        path: "/global/administration/organizations/new",
        name: "NewOrganization",
        meta: { title: "Empresas" },
        component: () => import(/* webpackChunkName: "organization" */ "../views/Organizations/GetOrganizationView.vue"),
    },
    {
        path: "/global/administration/organizations/:id",
        name: "ViewOrganization",
        meta: { title: "Empresas" },
        component: () => import(/* webpackChunkName: "organization" */ "../views/Organizations/GetOrganizationView.vue"),
    },
    {
        path: "/global/administration/organizations/:id/edit",
        name: "EditOrganization",
        meta: { title: "Empresas" },
        component: () => import(/* webpackChunkName: "organization" */ "../views/Organizations/GetOrganizationView.vue"),
    },
    // WorkCalendars
    {
        path: "/global/hhrr/work-calendars",
        name: "GetAllWorkCalendars",
        meta: { title: "Calendarios laborales" },
        component: () => import(/* webpackChunkName: "hhrr" */ "../views/WorkCalendars/GetAllWorkCalendarsView.vue"),
    },
    {
        path: "/global/hhrr/incidents",
        name: "GetAllTimeRecordIncidents",
        meta: {
            title: "Incidentes"
        },
        component: () =>
            import(/* webpackChunkName: "hhrr" */ "../views/TimeRecords/GetAllTimeRecordsView.vue"),
    }, {
        path: "/global/hhrr/time-records",
        name: "GetAllTimeRecords",
        meta: { title: "Registros horarios" },
        component: () =>
            import(/* webpackChunkName: "hhrr" */ "../views/TimeRecords/GetAllTimeRecordsView.vue"),
    }, {
        path: "/global/hhrr/monthly-view",
        name: "GetTimeRecordMonthlyView",
        meta: { title: "Vista mensual" },
        component: () =>
            import(/* webpackChunkName: "hhrr" */ "../views/TimeRecords/GetTimeRecordMonthlyView.vue"),
    }, {
        path: "/global/hhrr/yearly-view",
        name: "GetTimeRecordYearlyView",
        meta: { title: "Vista anual" },
        component: () =>
            import(/* webpackChunkName: "hhrr" */ "../views/TimeRecords/GetTimeRecordYearlyView.vue"),
    }, {
        path: "/global/hhrr/employees",
        name: "GetAllEmployees",
        meta: { title: "Empleados" },
        component: () =>
            import(/* webpackChunkName: "hhrr" */ "../views/Employees/GetAllEmployeesView.vue"),
    },
    {
        path: "/global/hhrr/employees/new",
        name: "NewEmployee",
        meta: { title: "Empleados" },
        component: () =>
            import(/* webpackChunkName: "hhrr" */ "../views/Employees/GetEmployeeView.vue"),
    },
    {
        path: "/global/hhrr/employees/:id",
        name: "ViewEmployee",
        meta: { title: "Empleados" },
        component: () =>
            import(/* webpackChunkName: "hhrr" */ "../views/Employees/GetEmployeeView.vue"),
    },
    {
        path: "/global/hhrr/employees/:id/edit",
        name: "EditEmployee",
        meta: { title: "Empleados" },
        component: () =>
            import(/* webpackChunkName: "hhrr" */ "../views/Employees/GetEmployeeView.vue"),
    },

    // WhatsAppAccounts
    {
        path: "/global/configuration/whatsapp-accounts",
        name: "GetAllWhatsAppAccounts",
        meta: { title: "Cuentas de WhatsApp" },
        component: () => import(/* webpackChunkName: "whatsappaccount" */ "../views/WhatsAppAccounts/GetAllWhatsAppAccountsView.vue"),
    },

    // Tasks
    {
        path: "/global/configuration/tasks",
        name: "GetAllTasks",
        meta: { title: "Tareas programadas" },
        component: () => import(/* webpackChunkName: "tasks" */ "../views/Tasks/GetAllTasksView.vue"),
    },

];


export default routes;
