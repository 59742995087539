
























































































import { EventBus } from "@/components/EventBus";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { BaseView } from "@/components/BaseView";
import { DataClient } from "@/services/DataClient";
import { Utilities } from "@/services/Utilities";
import { GetDayScheduleBusRoutesResponse, GetGuideResponse } from "@/services/ClientInterfaces";

@Component
export default class extends BaseView {
    @Prop({ required: true, type: String })
    itemId!: string;

    loading = true;
    item: GetGuideResponse | null = null;
    routes: GetDayScheduleBusRoutesResponse = [];

    @Watch("itemId", { immediate: true })
    async refresh() {
        this.loading = true;

        this.item = await DataClient.Guides.get({ id: this.itemId });

        const today = Utilities.todayAsYYYYMMDD();
        const routes = await DataClient.DaySchedules.getBusRoutes({ date: today });

        this.routes = [];

        for (const zone of routes) {
            const services: GetDayScheduleBusRoutesResponse[number]["services"] = [];

            for (const service of zone.services) {
                let addService = service.guideName === this.item.name;
                if (addService) {
                    services.push(service);
                }
            }

            if (services.length) {
                this.routes.push({
                    ...zone,
                    services,
                });
            }
        }

        this.loading = false;
    }

    get formattedGuideType() {
        if (this.item?.type === "SELFEMPLOYED") {
            return "Autónomo";
        } else if (this.item?.type === "BUSINESS") {
            return "Cooperativista";
        } else if (this.item?.type === "EMPLOYEE") {
            return "Empleado";
        }

        return "Desconocido";
    }

    getMeetingPointLastPickupTime(meetingPoint: GetDayScheduleBusRoutesResponse[number]["services"][number]["meetingPoints"][number]) {
        const lastPickupTime = meetingPoint.lastPickupTime;

        if (lastPickupTime) {
            // extract the time (HH:MM) from a the string like " 2023-08-04T11:48:35.433Z"
            return lastPickupTime.substring(11, 16);
        }

        return "";
    }

    getMeetingPointColor(meetingPoint: GetDayScheduleBusRoutesResponse[number]["services"][number]["meetingPoints"][number]) {
        const lastPickupTime = this.getMeetingPointLastPickupTime(meetingPoint);

        if (lastPickupTime) {
            if (lastPickupTime > meetingPoint.time) {
                return "red darken-1 white--text";
            }
        }

        return "green white--text";
    }

    getMeetingPointStyle(meetingPoint: GetDayScheduleBusRoutesResponse[number]["services"][number]["meetingPoints"][number]) {
        return "border: 3px; border-radius: 4px; padding: 4px;";
    }

    openGuide() {
        this.$emit("open", this.itemId);
    }
}
