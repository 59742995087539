import { RouteConfig } from "vue-router";

const routes: Array<RouteConfig> = [
    {
        path: "/websites/:id/configuration",
        name: "GetWebsiteConfiguration",
        meta: { title: "Configuración del sitio web" },
        component: () =>
            import(/* webpackChunkName: "websites" */ "../views/Websites/GetWebsiteConfigurationView.vue"),
    },
    {
        path: "/websites/:id/home-content",
        name: "GetWebsiteHomeContent",
        meta: { title: "Contenido del sitio web" },
        component: () =>
            import(/* webpackChunkName: "websites" */ "../views/Websites/GetWebsiteHomeContentView.vue"),
    },
    {
        path: "/websites/:websiteId/activity-sheets",
        name: "GetAllActivitySheets",
        meta: { title: "Fichas de actividad" },
        component: () =>
            import(/* webpackChunkName: "websites" */ "../views/ActivitySheets/GetAllActivitySheetsView.vue"),
    },
    {
        path: "/websites/:websiteId/activity-sheets/:id",
        name: "ViewActivitySheet",
        meta: { title: "Fichas de actividad" },
        component: () =>
            import(/* webpackChunkName: "websites" */ "../views/ActivitySheets/GetActivitySheetView.vue"),
    },
    {
        path: "/websites/:websiteId/activity-sheets/:id/edit",
        name: "EditActivitySheet",
        meta: { title: "Fichas de actividad" },
        component: () =>
            import(/* webpackChunkName: "websites" */ "../views/ActivitySheets/GetActivitySheetView.vue"),
    },
    {
        path: "/websites/:websiteId/posts",
        name: "GetAllPosts",
        meta: { title: "Publicaciones" },
        component: () =>
            import(/* webpackChunkName: "post" */ "../views/Posts/GetAllPostsView.vue"),
    }, {
        path: "/websites/:websiteId/posts/new",
        name: "NewPost",
        meta: { title: "Publicaciones" },
        component: () =>
            import(/* webpackChunkName: "post" */ "../views/Posts/GetPostView.vue"),
    }, {
        path: "/websites/:websiteId/posts/:id",
        name: "ViewPost",
        meta: { title: "Publicaciones" },
        component: () =>
            import(/* webpackChunkName: "post" */ "../views/Posts/GetPostView.vue"),
    }, {
        path: "/websites/:websiteId/posts/edit",
        name: "EditPost",
        meta: { title: "Publicaciones" },
        component: () =>
            import(/* webpackChunkName: "post" */ "../views/Posts/GetPostView.vue"),
    },

];


export default routes;
