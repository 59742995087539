






































































































































































import { BaseView } from "@/components/BaseView";
import { EventBus } from "@/components/EventBus";
import { BookingContextMenuRequest, DataClient } from "@/services/DataClient";
import { Utilities } from "@/services/Utilities";
import { Component, Vue, Watch } from "vue-property-decorator";

@Component
export default class extends BaseView {
    contextMenuVisible = false;
    contextMenuX = 0;
    contextMenuY = 0;

    attach: any = "";

    updatingContext = false;

    contextInformation = {
        warning: "",

        // permissions
        allowChangeOccupancy: false,
        allowChangeLanguage: false,
        allowNoShow: false,
        allowShow: false,
        allowCancel: false,

        id: "",
        reference: "",
        status: "",
        agency: "",
        pickupServiceId: "",
        pickupServiceName: "",
        pickupGuide: "",
        pickupBusNumber: "",
        activityServiceId: "",
        activityServiceName: "",
        activityGuide: "",
        activityBusNumber: "",
        language: "",
        languageName: "",
        adults: 0,
        children: 0,
        babies: 0,
        newAdults: 0,
        newChildren: 0,
        newBabies: 0,
        editOccupancy: false,
    };

    resultAction: "none" | "select-service" | "edit-booking" | "change" = "none";
    date?: string;
    scheduledServiceId?: string;

    onChange?: () => void = undefined;
    onEdit?: () => void = undefined;
    onSelectService?: (id: string, date: string) => void = undefined;

    languages = [
        { id: "es", name: "Español" },
        { id: "en", name: "Inglés" },
        { id: "de", name: "Alemán" },
        { id: "fr", name: "Francés" },
        { id: "it", name: "Italiano" },
        { id: "cz", name: "Checo" },
        { id: "pt", name: "Portugués" },
        { id: "ru", name: "Ruso" },
        { id: "ro", name: "Rumano" },
        { id: "pl", name: "Polaco" },
    ];

    mounted() {
        EventBus.$on(DataClient.Dialogs.OpenBookingContextEvent, this.handleOpenEvent);
    }

    beforeDestroy() {
        EventBus.$off(DataClient.Dialogs.OpenBookingContextEvent, this.handleOpenEvent);
    }

    @Watch("contextMenuVisible")
    onContextMenuVisibleChanged(newValue: boolean) {
        if (!newValue) {
            this.reset();
            DataClient.Dialogs.bookingContext(undefined, true);
        }
    }

    reset() {
        console.log("reset");

        this.contextMenuVisible = false;
        this.onChange = undefined;
        this.onEdit = undefined;
        this.onSelectService = undefined;
    }

    async handleOpenEvent(event: BookingContextMenuRequest) {
        event.event.preventDefault();

        this.contextMenuX = event.event.clientX;
        this.contextMenuY = event.event.clientY;
        this.attach = event.attach;

        const [booking, information] = await Promise.all([DataClient.Bookings.get({ id: event.id }), DataClient.Bookings.getPickupInformation({ id: event.id })]);

        this.onChange = event.onChange;
        this.onEdit = event.onEdit;
        this.onSelectService = event.onSelectService;

        let allowModification = true;
        const isCancelled = booking.status === "CANCELLED";

        const isInvoiced = booking.invoicingSlots.some((x) => x.invoiceId !== "");

        if (booking.dayClosed) {
            this.contextInformation.warning = "Operativa cerrada";
            allowModification = false;
        } else if (isInvoiced) {
            this.contextInformation.warning = "Reserva ya facturada";
            allowModification = false;
        } else {
            this.contextInformation.warning = "";
        }

        this.contextInformation.allowChangeOccupancy = allowModification && !isCancelled;
        this.contextInformation.allowChangeLanguage = allowModification && !isCancelled;
        this.contextInformation.allowNoShow = booking.allowNoShow;
        this.contextInformation.allowShow = booking.allowNoShow;
        this.contextInformation.allowCancel = allowModification && (booking.status === "CONFIRMED" || booking.status === "NOSHOW");

        this.contextInformation.id = booking.id;
        this.contextInformation.reference = booking.reference;
        this.contextInformation.status = booking.status;

        this.date = booking.date;
        this.scheduledServiceId = "";

        if (information.pickupServiceId) {
            this.contextInformation.pickupServiceId = information.pickupServiceId;
            this.contextInformation.pickupServiceName = information.pickupServiceName;
            this.contextInformation.pickupGuide = information.pickupGuideName;
            this.contextInformation.pickupBusNumber = information.pickupBusNumber;
        } else {
            this.contextInformation.pickupServiceId = "";
            this.contextInformation.pickupServiceName = "";
            this.contextInformation.pickupGuide = "";
            this.contextInformation.pickupBusNumber = "";
        }

        if (information.activityServiceId) {
            this.contextInformation.activityServiceId = information.activityServiceId;
            this.contextInformation.activityServiceName = information.activityServiceName;
            this.contextInformation.activityGuide = information.activityGuideName;
            this.contextInformation.activityBusNumber = information.activityBusNumber;
        } else {
            this.contextInformation.activityServiceId = "";
            this.contextInformation.activityServiceName = "";
            this.contextInformation.activityGuide = "";
            this.contextInformation.activityBusNumber = "";
        }

        this.contextInformation.editOccupancy = false;

        this.contextInformation.adults = booking.adults;
        this.contextInformation.children = booking.children;
        this.contextInformation.babies = booking.babies;
        this.contextInformation.newAdults = booking.adults;
        this.contextInformation.newChildren = booking.children;
        this.contextInformation.newBabies = booking.babies;

        this.contextInformation.language = booking.language;
        this.contextInformation.languageName = this.languages.find((x) => x.id === booking.language)?.name || "Idioma desconocido";

        this.$nextTick(() => {
            this.contextMenuVisible = true;
        });
    }

    switchToAnotherScheduledService(id: string) {
        if (this.onSelectService) {
            this.onSelectService(id, this.date || "");
        }

        this.contextMenuVisible = false;
    }

    async editBookingOccupancy() {
        this.updatingContext = true;

        await this.secureExecution(async () => {
            await DataClient.Bookings.editOccupancy({
                id: this.contextInformation.id,
                adults: this.contextInformation.newAdults,
                children: this.contextInformation.newChildren,
                babies: this.contextInformation.newBabies,
            });

            if (this.onChange) {
                this.onChange();
            }
        });

        this.updatingContext = false;
        this.contextMenuVisible = false;
    }

    async editBookingLanguage(language: string) {
        this.updatingContext = true;

        await this.secureExecution(async () => {
            await DataClient.Bookings.editLanguage({
                id: this.contextInformation.id,
                language: language,
            });

            if (this.onChange) {
                this.onChange();
            }
        });

        this.updatingContext = false;
        this.contextMenuVisible = false;
    }

    async noShowBooking() {
        const yes = await DataClient.Dialogs.confirmation({
            title: "Marcar como no show",
            message: "¿Está seguro que desea marcar esta reserva como NO SHOW?",
            icon: "mdi-eye-off-outline",
            attach: this.attach,
        });

        if (yes) {
            await this.secureExecution(async () => {
                await DataClient.Bookings.noShow({
                    id: this.contextInformation.id,
                });

                if (this.onChange) {
                    this.onChange();
                }
            });

            this.contextMenuVisible = false;
        }
    }

    async showBooking() {
        await this.secureExecution(async () => {
            await DataClient.Bookings.show({
                id: this.contextInformation.id,
            });

            if (this.onChange) {
                this.onChange();
            }
        });

        this.contextMenuVisible = false;
    }

    async viewBooking() {
        if (this.onEdit) {
            this.onEdit();
        }

        this.contextMenuVisible = false;
    }

    async cancelBooking() {
        const yes = await DataClient.Dialogs.confirmation({
            title: "Cancelar reserva",
            message: "¿Está seguro de que desea cancelar la reserva?",
            icon: "mdi-cancel",
            attach: this.attach,
        });

        if (yes) {
            await this.secureExecution(async () => {
                await DataClient.Bookings.cancel({
                    id: this.contextInformation.id,
                });

                if (this.onChange) {
                    this.onChange();
                }
            });

            this.contextMenuVisible = false;
        }
    }
}
