






































































































import { EventBus } from "@/components/EventBus";
import { OpenCommandCenterRequest, DataClient } from "@/services/DataClient";
import { SearchCommandCenterResponse } from "@/services/ClientInterfaces";
import { Utilities } from "@/services/Utilities";
import { Component, Vue, Watch } from "vue-property-decorator";
import { BaseView } from "@/components/BaseView";
import CommandCenterBookingView from "./Components/CommandCenterBookingView.vue";
import CommandCenterGuideView from "./Components/CommandCenterGuideView.vue";

@Component({
    components: {
        CommandCenterBookingView,
        CommandCenterGuideView,
    },
})
export default class extends BaseView {
    dialog = false;

    query = "";
    searching = false;

    selectedType = "";
    selectedId = "";

    results: SearchCommandCenterResponse = [];

    mounted() {
        EventBus.$on(DataClient.CommandCenter.OpenCommandCenterDialogEvent, this.handleOpenEvent);
    }

    beforeDestroy() {
        EventBus.$off(DataClient.CommandCenter.OpenCommandCenterDialogEvent, this.handleOpenEvent);
    }

    get somethingSelected() {
        return this.selectedType && this.selectedId;
    }

    async handleOpenEvent(event: OpenCommandCenterRequest) {
        if (this.dialog) {
            this.dialog = false;
            return;
        }

        this.query = "";
        this.results = [];

        this.search();

        this.dialog = true;

        this.focusSearchBar();
    }

    accept() {
        this.dialog = false;

        DataClient.CommandCenter.open(true, true);
    }

    focusSearchBar() {
        Utilities.focus(this, "command-center-search");
    }

    cancel() {
        this.dialog = false;

        DataClient.CommandCenter.open(false, true);
    }

    async search() {
        this.searching = true;

        this.selectedType = "";
        this.selectedId = "";

        try {
            this.results = await DataClient.CommandCenter.search({ query: this.query });

            if (this.results.length === 1 && this.results[0].items.length === 1) {
                this.selectItem(this.results[0].type, this.results[0].items[0].id);
            }
        } catch (error) {
            this.openErrorDialog("Error durante la búsqueda", error);
        } finally {
            this.searching = false;
        }
    }

    clear() {
        this.query = "";
        this.search();
    }

    formatDate(date?: string) {
        return Utilities.formatDateToDDMMYYYY(date || "");
    }

    async selectItem(type: string, id: string) {
        this.selectedType = type;
        this.selectedId = id;

        this.focusSearchBar();
    }

    openBooking(id: string) {
        this.cancel();
        this.$router.push({ name: "ViewBooking", params: { id } });
    }

    openGuide(id: string) {
        this.cancel();
        this.$router.push({ name: "ViewGuide", params: { id } });
    }
}
