




















import { EventBus } from "@/components/EventBus";
import { OpenProgressRequest, UpdateProgressRequest, DataClient } from "@/services/DataClient";
import { Utilities } from "@/services/Utilities";
import { Component, Vue } from "vue-property-decorator";

@Component
export default class extends Vue {
    dialog = false;

    title = "";
    message = "";
    error = "";
    width = "800";

    mounted() {
        EventBus.$on(DataClient.Dialogs.OpenProgressDialogEvent, this.handleOpenEvent);
        EventBus.$on(DataClient.Dialogs.CloseProgressDialogEvent, this.handleCloseEvent);
        EventBus.$on(DataClient.Dialogs.UpdateProgressDialogEvent, this.handleUpdateEvent);
    }

    beforeDestroy() {
        EventBus.$off(DataClient.Dialogs.OpenProgressDialogEvent, this.handleOpenEvent);
        EventBus.$off(DataClient.Dialogs.CloseProgressDialogEvent, this.handleCloseEvent);
        EventBus.$off(DataClient.Dialogs.UpdateProgressDialogEvent, this.handleUpdateEvent);
    }

    reset() {
        this.dialog = false;
    }

    async handleOpenEvent(event: OpenProgressRequest) {
        this.title = event.title;
        this.message = "";
        this.error = "";

        this.dialog = true;

        console.log("Progress dialog opened");
    }

    async handleCloseEvent() {
        this.reset();
    }

    async handleUpdateEvent(event: UpdateProgressRequest) {
        this.message = event.message || "";
        this.error = event.error || "";
    }

    close() {
        this.reset();
    }
}
