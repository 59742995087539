import { RouteConfig } from "vue-router";

const routes: Array<RouteConfig> = [
    {
        path: "/transfers/operations/day-schedule/:type",
        name: "TransferDaySchedule",
        meta: { title: "Planificación diaria" },
        component: () => import(/* webpackChunkName: "transferhotel" */ "../views/TransferOperations/TransferDayScheduleView.vue"),
    },
    {
        path: "/transfers/operations/flights",
        name: "GetTransferFlights",
        meta: { title: "Planificación de vuelos" },
        component: () => import(/* webpackChunkName: "transferhotel" */ "../views/TransferOperations/GetTransferFlightsView.vue"),
    },
    {
        path: "/transfers/operations/incidents",
        name: "GetAllTransferIncidents",
        meta: { title: "Incidencias" },
        component: () => import(/* webpackChunkName: "transferhotel" */ "../views/TransferOperations/GetTransferIncidentsView.vue"),
    },
    // TransferZones
    {
        path: "/transfers/maintenances/zones",
        name: "GetAllTransferZones",
        meta: { title: "Zonas de traslado" },
        component: () => import(/* webpackChunkName: "transferzone" */ "../views/TransferZones/GetAllTransferZonesView.vue"),
    },
    {
        path: "/transfers/maintenances/zones/new",
        name: "NewTransferZone",
        meta: { title: "Zonas de traslado" },
        component: () => import(/* webpackChunkName: "transferzone" */ "../views/TransferZones/GetTransferZoneView.vue"),
    },
    {
        path: "/transfers/maintenances/zones/:id",
        name: "ViewTransferZone",
        meta: { title: "Zonas de traslado" },
        component: () => import(/* webpackChunkName: "transferzone" */ "../views/TransferZones/GetTransferZoneView.vue"),
    },
    {
        path: "/transfers/maintenances/zones/:id/edit",
        name: "EditTransferZone",
        meta: { title: "Zonas de traslado" },
        component: () => import(/* webpackChunkName: "transferzone" */ "../views/TransferZones/GetTransferZoneView.vue"),
    },
    // Airlines
    {
        path: "/transfers/maintenances/airlines",
        name: "GetAllAirlines",
        meta: { title: "Aerolíneas" },
        component: () => import(/* webpackChunkName: "airline" */ "../views/Airlines/GetAllAirlinesView.vue"),
    },
    {
        path: "/transfers/maintenances/airlines/new",
        name: "NewAirline",
        meta: { title: "Aerolíneas" },
        component: () => import(/* webpackChunkName: "airline" */ "../views/Airlines/GetAirlineView.vue"),
    },
    {
        path: "/transfers/maintenances/airlines/:id",
        name: "ViewAirline",
        meta: { title: "Aerolíneas" },
        component: () => import(/* webpackChunkName: "airline" */ "../views/Airlines/GetAirlineView.vue"),
    },
    {
        path: "/transfers/maintenances/airlines/:id/edit",
        name: "EditAirline",
        meta: { title: "Aerolíneas" },
        component: () => import(/* webpackChunkName: "airline" */ "../views/Airlines/GetAirlineView.vue"),
    },
    // Airports
    {
        path: "/transfers/maintenances/airports",
        name: "GetAllAirports",
        meta: { title: "Aeropuertos" },
        component: () => import(/* webpackChunkName: "airport" */ "../views/Airports/GetAllAirportsView.vue"),
    },
    {
        path: "/transfers/maintenances/airports/new",
        name: "NewAirport",
        meta: { title: "Aeropuertos" },
        component: () => import(/* webpackChunkName: "airport" */ "../views/Airports/GetAirportView.vue"),
    },
    {
        path: "/transfers/maintenances/airports/:id",
        name: "ViewAirport",
        meta: { title: "Aeropuertos" },
        component: () => import(/* webpackChunkName: "airport" */ "../views/Airports/GetAirportView.vue"),
    },
    {
        path: "/transfers/maintenances/airports/:id/edit",
        name: "EditAirport",
        meta: { title: "Aeropuertos" },
        component: () => import(/* webpackChunkName: "airport" */ "../views/Airports/GetAirportView.vue"),
    },
    // Flights
    {
        path: "/transfers/operations/flights",
        name: "GetAllFlights",
        meta: { title: "Vuelos" },
        component: () => import(/* webpackChunkName: "flight" */ "../views/Flights/GetAllFlightsView.vue"),
    },
    {
        path: "/transfers/operations/flights/new",
        name: "NewFlight",
        meta: { title: "Vuelos" },
        component: () => import(/* webpackChunkName: "flight" */ "../views/Flights/GetFlightView.vue"),
    },
    {
        path: "/transfers/operations/flights/:id",
        name: "ViewFlight",
        meta: { title: "Vuelos" },
        component: () => import(/* webpackChunkName: "flight" */ "../views/Flights/GetFlightView.vue"),
    },
    {
        path: "/transfers/operations/flights/:id/edit",
        name: "EditFlight",
        meta: { title: "Vuelos" },
        component: () => import(/* webpackChunkName: "flight" */ "../views/Flights/GetFlightView.vue"),
    },
    // TransferBookings
    {
        path: "/transfers/bookings/booking-center",
        name: "GetAllTransferBookings",
        meta: { title: "Reservas" },
        component: () => import(/* webpackChunkName: "transferbooking" */ "../views/TransferBookings/GetAllTransferBookingsView.vue"),
    },
    {
        path: "/transfers/bookings/booking-center/:id",
        name: "ViewTransferBooking",
        meta: { title: "Reservas" },
        component: () => import(/* webpackChunkName: "transferbooking" */ "../views/TransferBookings/GetTransferBookingView.vue"),
    },
    // TransferCustomers
    {
        path: "/transfers/bookings/customer-center",
        name: "GetAllTransferCustomers",
        meta: { title: "Clientes" },
        component: () => import(/* webpackChunkName: "transferbooking" */ "../views/TransferBookings/GetAllTransferCustomersView.vue"),
    },
    // TransferAgencies
    {
        path: "/transfers/maintenances/transferagencies",
        name: "GetAllTransferAgencies",
        meta: { title: "Agencias" },
        component: () => import(/* webpackChunkName: "transferagency" */ "../views/TransferAgencies/GetAllTransferAgenciesView.vue"),
    },
    {
        path: "/transfers/maintenances/transferagencies/new",
        name: "NewTransferAgency",
        meta: { title: "Agencias" },
        component: () => import(/* webpackChunkName: "transferagency" */ "../views/TransferAgencies/GetTransferAgencyView.vue"),
    },
    {
        path: "/transfers/maintenances/transferagencies/:id",
        name: "ViewTransferAgency",
        meta: { title: "Agencias" },
        component: () => import(/* webpackChunkName: "transferagency" */ "../views/TransferAgencies/GetTransferAgencyView.vue"),
    },
    {
        path: "/transfers/maintenances/transferagencies/:id/edit",
        name: "EditTransferAgency",
        meta: { title: "Agencias" },
        component: () => import(/* webpackChunkName: "transferagency" */ "../views/TransferAgencies/GetTransferAgencyView.vue"),
    },
    // TransferProducts
    {
        path: "/transfers/maintenances/products",
        name: "GetAllTransferProducts",
        meta: { title: "Productos" },
        component: () => import(/* webpackChunkName: "transferproduct" */ "../views/TransferProducts/GetAllTransferProductsView.vue"),
    },
    {
        path: "/transfers/maintenances/products/new",
        name: "NewTransferProduct",
        meta: { title: "Productos" },
        component: () => import(/* webpackChunkName: "transferproduct" */ "../views/TransferProducts/GetTransferProductView.vue"),
    },
    {
        path: "/transfers/maintenances/products/:id",
        name: "ViewTransferProduct",
        meta: { title: "Productos" },
        component: () => import(/* webpackChunkName: "transferproduct" */ "../views/TransferProducts/GetTransferProductView.vue"),
    },
    {
        path: "/transfers/maintenances/products/:id/edit",
        name: "EditTransferProduct",
        meta: { title: "Productos" },
        component: () => import(/* webpackChunkName: "transferproduct" */ "../views/TransferProducts/GetTransferProductView.vue"),
    },
    // TransferHotels
    {
        path: "/transfers/maintenances/hotels",
        name: "GetAllTransferHotels",
        meta: { title: "Hoteles" },
        component: () => import(/* webpackChunkName: "transferhotel" */ "../views/TransferHotels/GetAllTransferHotelsView.vue"),
    },
    {
        path: "/transfers/maintenances/hotels/new",
        name: "NewTransferHotel",
        meta: { title: "Hoteles" },
        component: () => import(/* webpackChunkName: "transferhotel" */ "../views/TransferHotels/GetTransferHotelView.vue"),
    },
    {
        path: "/transfers/maintenances/hotels/:id",
        name: "ViewTransferHotel",
        meta: { title: "Hoteles" },
        component: () => import(/* webpackChunkName: "transferhotel" */ "../views/TransferHotels/GetTransferHotelView.vue"),
    },
    {
        path: "/transfers/maintenances/hotels/:id/edit",
        name: "EditTransferHotel",
        meta: { title: "Hoteles" },
        component: () => import(/* webpackChunkName: "transferhotel" */ "../views/TransferHotels/GetTransferHotelView.vue"),
    },
];


export default routes;
